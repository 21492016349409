<template>
  <v-footer
    class="pt-8 pb-4 pt-md-12 pb-md-8"
    :class="[
      'bg-ui-kit-bg-gray-40 flex-grow-0 px-0'
    ]"
  >
    <v-container>
      <v-row no-gutters>
        <v-col>
          <v-row>
            <AppFooterCol
              cols="12"
              :links="firstColLinks"
            >
              <template #logo>
                <v-img
                  src="/images/logo/medflex-black.svg"
                  alt="МедФлекс"
                  width="159"
                  height="32"
                />
              </template>
            </AppFooterCol>
            <AppFooterCol
              cols="12"
              class="mt-5"
              :links="contactsLinks"
            />
          </v-row>
        </v-col>
        <AppFooterCol
          class="mt-10 mt-md-0"
          cols="12"
          md="4"
          title="Наши проекты"
          :links="projectsLinks"
        />
        <AppFooterCol
          class="mt-10 mt-md-0"
          cols="12"
          md="4"
          title="Условия использования"
          :links="documentsLinks"
        />
      </v-row>
      <div
        class="mt-4 mt-md-13"
        :class="['d-flex align-start']"
      >
        <v-img
          src="/images/software-register.svg"
          alt="Единый реестр российских программ для ЭВМ"
          width="64"
          height="76"
          class="mr-8 flex-grow-0 d-none d-md-inline"
        />
        <div>
          <p class="text-body-2 text-ui-kit-text-secondary">
            Программное обеспечение МедФлекс предоставляется на основании подписания договора — безвозмездно.
          </p>
          <p class="mt-1 text-body-2 text-ui-kit-text-secondary">
            Плата взимается за совершённые транзакции в соответствии с тарифами, определёнными договором.
          </p>
          <v-img
            src="/images/software-register.svg"
            alt="Единый реестр российских программ для ЭВМ"
            width="64"
            height="76"
            class="mt-10 flex-grow-0 d-inline-block d-md-none"
          />
          <p
            class="mt-4 mt-md-3"
            :class="['text-body-2 text-ui-kit-text-info']"
          >
            © 2011-{{ new Date().getFullYear() }}, ООО «МедРокет»
          </p>
        </div>
      </div>
    </v-container>
  </v-footer>
</template>

<script lang="ts">
import type { AppFooterColLink } from '~/components/AppFooter/AppFooterCol.vue'

export default defineComponent({
  name: 'AppFooter',
  setup() {
    const firstColLinks: AppFooterColLink[] = [
      {
        linkText: 'О компании',
        href: 'https://medrocket.ru/'
      },
      {
        prg: true,
        linkText: 'Карьера у нас',
        href: 'https://rabota.medrocket.ru/'
      },
      {
        linkText: 'База знаний',
        href: 'https://help.medflex.ru/'
      }
    ]

    const projectsLinks: AppFooterColLink[] = [
      {
        linkText: 'ПроДокторов',
        href: 'https://prodoctorov.ru/'
      },
      {
        linkText: 'МедТочка',
        href: 'https://medtochka.ru/'
      },
      {
        linkText: 'ПроТаблетки',
        href: 'https://protabletky.ru/'
      },
      {
        linkText: 'ПроБолезни',
        href: 'https://probolezny.ru/'
      },
      {
        linkText: 'МедЛок',
        href: useRuntimeConfig().public.mlUrl
      }
    ]
    const documentsLinks: AppFooterColLink[] = [
      {
        linkText: 'Политика безопасности',
        href: 'https://medflex.ru/static/dms/pdf/personal_data_processing_policy.pdf'
      },
      {
        linkText: 'Пользовательское соглашение',
        href: 'https://medflex.ru/static/dms/pdf/user_agreement.pdf'
      },
      {
        linkText: 'Лицензионный договор',
        href: 'https://medflex.ru/static/dms/pdf/medflex_contract.pdf'
      },
      {
        linkText: 'Руководство пользователя',
        href: 'https://medflex.ru/static/docs/user_manual.pdf'
      }
    ]
    const contactsLinks: AppFooterColLink[] = [
      {
        text: 'Телефон:',
        linkText: '8 800 707-31-29',
        href: 'tel:8(800)707-31-29'
      },
      {
        text: 'Email:',
        linkText: 'help@medrocket.ru',
        href: 'mailto:help@medrocket.ru'
      }
    ]

    return {
      firstColLinks,
      projectsLinks,
      documentsLinks,
      contactsLinks
    }
  }
})
</script>
