<template>
  <v-app full-height>
    <AppHeader />
    <v-main class="ssr-fix-main">
      <slot />
      <AppFooter />
    </v-main>
  </v-app>
</template>

<script lang="ts" setup>
import { ASYNC_DATA_KEYS } from '~/constants'
import { homeApi } from '~/api'
import { partnerSectionApi } from '~/components/PartnerSection/api'
import { getMisStatistics } from '~/api/misStatistics'
import { getPartnersFeaturesTableInfo } from '~/features/PartnersTable/api'

const [{ data }] = $featureFlag('feature_add_table_partner_integration')
  ? await Promise.all([
    useAsyncData(ASYNC_DATA_KEYS.statics, () => homeApi.getStatistics()),
    useAsyncData(ASYNC_DATA_KEYS.logos, () => partnerSectionApi.getLogos()),
    useAsyncData(ASYNC_DATA_KEYS.misList, () => getMisStatistics(), {
      default() {
        return []
      }
    }),
    useAsyncData(ASYNC_DATA_KEYS.partnersFeaturesTableInfo, () => getPartnersFeaturesTableInfo(), {
      default() {
        return []
      }
    })
  ])
  : await Promise.all([
    useAsyncData(ASYNC_DATA_KEYS.statics, () => homeApi.getStatistics()),
    useAsyncData(ASYNC_DATA_KEYS.logos, () => partnerSectionApi.getLogos()),
    useAsyncData(ASYNC_DATA_KEYS.misList, () => getMisStatistics(), {
      default() {
        return []
      }
    })
  ])

if (!data.value) {
  throw createError({
    statusCode: 500
  })
}
</script>
