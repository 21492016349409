type FeatureStatus = 'available' | 'not_released'

export type PartnerTableInfoItem = {
  name: string
  lpusCount: number
  triggerMailings: FeatureStatus
  omnichannelMessangers: FeatureStatus
  marketingMailings: FeatureStatus
  isMedlock: boolean
}

export const getPartnersFeaturesTableInfo = () => $apiFetch<PartnerTableInfoItem[]>('/patient_features/')
